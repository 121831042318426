import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Tabs,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Icon } from "@mdi/react";
import { mdiContentCopy, mdiDownload } from "@mdi/js";
import { QRCode } from "react-qrcode-logo";

import { phoneRegex } from "@heylog-app/shared/util";
import {
  MessageDirectionEnum,
  deeplSupportedLanguages,
  TranslationServiceEnum,
  googleSupportedLanguages,
} from "@heylog-app/shared/types";

import logo from "../../../assets/Heylog-Icon-Transparent-M.png";
import {
  StyledDecoration,
  StyledDialogContainer,
  StyledDialogFooter,
  StyledDownloadButton,
  StyledInputDescription,
  StyledMessageGroupWrapper,
  StyledTab,
  StyledTabsContainer,
} from "./add-contact-form.styles";
import {
  useApiClientContext,
  useDialog,
  useOrder,
  useSnackbar,
  useUser,
  useWorkspace,
} from "../../../hooks";
import {
  ControlledSelect,
  ErrorMessage,
  MessageBubble,
  ModalClose,
} from "../../ui";
import {
  buildInviteMessage,
  cleanInviteMessage,
  inviteContactAPI,
  useErrorMessage,
} from "../../../util";
import { StyledMessageGroup } from "../../ui/message-group/message-group.styles";
import { StyledMessageAuthorText } from "../../ui/message-author/message-author.styles";
import { staticMockedMessage } from "./static-mocked-message";
import { TabPanel } from "./components/tab-panel";
import { FlashSnackbar } from "../../snackbars/flash-snackbar";
import { StyledDialogActions } from "../edit-contact-form/edit-contact-form.styles";

import type { SelectChangeEvent } from "@mui/material";
import type { Control, FieldValues } from "react-hook-form";
import type { FC } from "react";
import type { FullContactResInterface } from "@heylog-app/shared/types";

type FormData = {
  phone: string;
  chatLanguage: string;
};

type CreateContactFormProps = {
  contact?: FullContactResInterface; // provide ID if editing a contact
  onSuccess: () => void;
  onCancel: () => void;
};

export const AddContactForm: FC<CreateContactFormProps> = ({
  contact,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const { workspaceId = "", orderId, shipmentId } = params;

  // hacky way to make this work on both order and shipment pages: if orderId is undefined, we try again for the shipmentId
  const { order } = useOrder(orderId || shipmentId);
  const { user } = useUser();
  const { apiClient } = useApiClientContext();
  const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

  const { workspace } = useWorkspace(workspaceId, user);
  const defaultLanguageSelected = "de";
  const [chatLanguage, setChatLanguage] = useState(defaultLanguageSelected);
  const [mockedMessageText, setMockedMessageText] = useState("");

  const supportedLanguages =
    workspace?.translationService === TranslationServiceEnum.DEEPL
      ? deeplSupportedLanguages
      : googleSupportedLanguages;

  const {
    showDialog: showConfirmDialog,
    openDialog: openConfirmDialog,
    closeDialog: closeConfirmDialog,
  } = useDialog();

  const { errorMessage, setError } = useErrorMessage();

  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  // TODO OSU add join link from order entity if there is an orderId
  const inviteLink = order?.orderJoinLink
    ? order?.orderJoinLink
    : workspace?.joinLink;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<FormData>({
    defaultValues: {
      phone: "",
    },
  });

  useEffect(() => {
    setMockedMessageText(buildInviteMessage(user, workspace, chatLanguage));
  }, [chatLanguage, user, workspace]);

  useEffect(() => {
    if (contact) {
      reset({
        phone: contact.phone || "",
      });
    }
  }, [contact, reset]);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const onFocusPhoneField = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (event.target.value === "") {
        reset({ phone: "+" });
      }
    },
    [reset]
  );

  const onSubmitSendSMSForm = (data: FormData) => {
    if (shipmentId !== undefined && !showConfirmDialog) {
      openConfirmDialog();
    } else {
      submitSendSMSForm(data);
    }
  };

  const submitSendSMSForm = (data: FormData) => {
    inviteContactAPI(apiClient, {
      data: {
        phone: data.phone,
        body: cleanInviteMessage(mockedMessageText as string),
        language: chatLanguage,
        ...(shipmentId && { orderid: parseInt(shipmentId) }),
      },
      workspaceId,
    })
      .then(() => {
        onSuccess();
        closeConfirmDialog();
      })
      .catch((e) => {
        setError(e);
        closeConfirmDialog();
      });
  };

  const handleCopyClick = () => {
    copyTextToClipboard(inviteLink || "")
      .then(() => {
        openSnackbar("success", t("contacts.form.linkIsCopied"));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const copyTextToClipboard = async (text: string) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const sortedLanguages = useMemo(() => {
    const supportedLanguagesMap = supportedLanguages.map((languageCode) => {
      return { code: languageCode, name: t(`languages.${languageCode}`) };
    });
    supportedLanguagesMap.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    return supportedLanguagesMap;
  }, [supportedLanguages, t]);

  const handleLanguageChange = useCallback(
    (event: SelectChangeEvent<unknown>) =>
      setChatLanguage(event.target.value as string),
    [setChatLanguage]
  );

  const downloadQRCode = () => {
    const canvas = document.createElement("canvas");
    const qrCode = document.getElementById("qr-code") as HTMLCanvasElement;
    const context = canvas.getContext("2d");

    canvas.height = 650;
    canvas.width = 600;

    if (context) {
      context.imageSmoothingEnabled = false;
      context.drawImage(qrCode, 0, 0, 600, 600);
      context.fillStyle = "black";
      context.font = "bold 32px Arial";
      context.fillText(`${workspace?.name}`, 0, canvas.height - 10);
    }

    const pngUrl = canvas
      ?.toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `heylog-${workspace?.name}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <StyledDialogContainer>
      <StyledTabsContainer>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <StyledTab label={t("actionLabels.sendSms")} {...a11yProps(0)} />
          <StyledTab
            label={t("actionLabels.shareLink")}
            data-test="invitelink-tab"
            {...a11yProps(1)}
          />
        </Tabs>
      </StyledTabsContainer>
      <StyledDecoration />

      <TabPanel value={tabIndex} index={0}>
        <form onSubmit={handleSubmit(onSubmitSendSMSForm)} noValidate>
          <DialogContent>
            <StyledInputDescription>
              {t("contacts.form.addContactBySMSHint")}
            </StyledInputDescription>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="phone"
                  render={({ field }) => (
                    <TextField
                      data-test="invite-with-phone-field"
                      label={t("contacts.form.labels.phone")}
                      fullWidth
                      type="tel"
                      error={!!errors.phone}
                      helperText={
                        errors.phone
                          ? t(`forms.errors.${errors.phone?.type ?? "invalid"}`)
                          : null
                      }
                      placeholder={"+43660..."}
                      onFocus={onFocusPhoneField}
                      {...register("phone", {
                        required: true,
                        pattern: phoneRegex,
                      })}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <ControlledSelect
                  margin="none"
                  control={control as unknown as Control<FieldValues>}
                  label={t("contacts.form.labels.chatLanguage")}
                  id="chatLanguage"
                  rules={{ required: true }}
                  selectOptions={{
                    error: !!errors.chatLanguage,
                    onChange: handleLanguageChange,
                    value: chatLanguage,
                  }}
                  errorMessage={
                    errors.chatLanguage && t("forms.errors.required")
                  }
                  defaultValue={defaultLanguageSelected}
                >
                  {sortedLanguages?.map((lang) => (
                    <MenuItem key={lang.code} value={lang.code}>
                      {lang.name}
                    </MenuItem>
                  ))}
                </ControlledSelect>
              </Grid>
              <Grid item xs={2}>
                <Button
                  data-test="send-invite-sms-button"
                  style={{ height: "100%" }}
                  fullWidth
                  variant="contained"
                  type="submit"
                >
                  {t("actionLabels.invite")}
                </Button>
              </Grid>
            </Grid>
            {errorMessage && (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ErrorMessage errorMessage={errorMessage} />
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <StyledDialogFooter>
            <StyledMessageGroupWrapper>
              <StyledMessageGroup
                $direction={MessageDirectionEnum.FROM_CONTACT}
              >
                <StyledMessageAuthorText>Heylog</StyledMessageAuthorText>
              </StyledMessageGroup>
              <StyledMessageGroup $direction={staticMockedMessage.direction}>
                <MessageBubble
                  message={{ ...staticMockedMessage, text: mockedMessageText }}
                />
              </StyledMessageGroup>
            </StyledMessageGroupWrapper>
          </StyledDialogFooter>

          <Dialog open={showConfirmDialog} onClose={closeConfirmDialog}>
            <ModalClose closeModalFn={closeConfirmDialog} />
            <DialogTitle id="confirm-invite-dialog-title">
              {t("orders.invite.confirm.title")}
            </DialogTitle>

            <DialogContent>
              <DialogContentText>
                {t("orders.invite.confirm.text", {
                  orderName: order?.refNumber,
                })}
              </DialogContentText>
            </DialogContent>

            <StyledDialogActions>
              <Button variant="outlined" onClick={closeConfirmDialog}>
                {t("actionLabels.cancel")}
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit(onSubmitSendSMSForm)}
                autoFocus
              >
                {t("actionLabels.confirm")}
              </Button>
            </StyledDialogActions>
          </Dialog>
        </form>
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <DialogContent>
          <StyledInputDescription>
            {t("contacts.form.copyInvitationLinkHint")}
          </StyledInputDescription>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField fullWidth type="text" value={inviteLink} disabled />
            </Grid>

            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                style={{ height: "100%" }}
                onClick={handleCopyClick}
                data-test="copy-invitelink-button"
                startIcon={<Icon path={mdiContentCopy} size={"1.5em"} />}
              >
                {t("contacts.form.copyLink")}
              </Button>
            </Grid>

            <FlashSnackbar
              controls={[stateSnackbar, openSnackbar, closeSnackbar]}
            />
          </Grid>
        </DialogContent>
        <StyledDialogFooter>
          <QRCode
            id="qr-code"
            value={inviteLink}
            size={150}
            removeQrCodeBehindLogo
            quietZone={0}
            logoWidth={25}
            logoImage={logo}
          />
          <StyledDownloadButton onClick={downloadQRCode} type="button">
            <Icon path={mdiDownload} size={"1em"} />{" "}
            {t("actionLabels.download")} (png)
          </StyledDownloadButton>
        </StyledDialogFooter>
      </TabPanel>
    </StyledDialogContainer>
  );
};
